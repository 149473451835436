// node modules
import React from 'react'
import { graphql, PageProps } from 'gatsby'

// layout
import Layout from 'components/layout/layout'
import Seo from 'components/layout/seo'

// hooks
import usePopup from 'hooks/usePopup'

// components
import Hero from 'components/pages/category-page/hero'
import Breadcrumbs from 'components/shared/breadcrumbs'
import Intro from 'components/pages/category-page/intro'
import Products from 'components/pages/category-page/products'
import Infobox from 'components/pages/category-page/infobox'
import About from 'components/pages/category-page/about'
import Contact from 'components/pages/category-page/contact'

// types
import { ProductThumbnail } from 'types/product'

const IndexPage: React.FC<PageProps<Queries.UtvPageQuery>> = ({ data }) => {
  const PAGE = data.wpPage?.utv
  const PAGE_SEO = data.wpPage?.seo!
  const PRODUCTS = data.allWpVehiclesArticle.nodes

  if (!PAGE || !PAGE_SEO) throw new Error("CMS data didn't load properly")

  const UTV_VEHICLES: ProductThumbnail[] =
    PRODUCTS?.filter(
      (product) =>
        product?.produkt?.productOptionsProductName &&
        product?.produkt?.productOptionsProductImage
    )
      ?.map(({ date, slug, produkt }) => ({
        image: {
          src: produkt?.productOptionsProductImage?.localFile?.childImageSharp!
            ?.gatsbyImageData,
          alt: produkt?.productOptionsProductImage?.altText!,
        },
        name: produkt?.productOptionsProductName!,
        priceFrom: produkt?.productOptionsPriceFrom!,
        noPrice: Boolean(produkt?.productOptionsNoPrice),
        category: produkt?.productOptionsProductType!,
        dateAdded: date!,
        slug: slug!,
      }))
      .sort(
        (a, b) =>
          new Date(a.dateAdded).getTime() - new Date(b.dateAdded).getTime()
      ) || []

  const ABOUT_SECTIONS = [
    {
      image: {
        src: PAGE?.utvAdvImg1?.localFile?.childImageSharp?.gatsbyImageData!,
        alt: PAGE?.utvAdvImg1?.altText!,
      },
      title: PAGE?.utvAdvSubtitle1!,
      description: PAGE?.utvAdvDescription1!,
    },
    {
      image: {
        src: PAGE?.utvAdvImg2?.localFile?.childImageSharp?.gatsbyImageData!,
        alt: PAGE?.utvAdvImg2?.altText!,
      },
      title: PAGE?.utvAdvSubtitle2!,
      description: PAGE?.utvAdvDescription2!,
    },
    {
      image: {
        src: PAGE?.utvAdvImg3?.localFile?.childImageSharp?.gatsbyImageData!,
        alt: PAGE?.utvAdvImg3?.altText!,
      },
      title: PAGE?.utvAdvSubtitle3!,
      description: PAGE?.utvAdvDescription3!,
    },
  ]

  const POPUP = {
    image: {
      src: PAGE?.popupImg?.localFile?.childImageSharp?.gatsbyImageData!,
      alt: PAGE?.popupImg?.altText ?? '',
    },
    delay: PAGE?.popupDelay,
    link: PAGE?.popupLink ?? null,
    visibleOnMobile: PAGE?.popupVisibleOnMobile ?? false,
  }

  usePopup(POPUP)

  return (
    <Layout>
      <Seo
        title={PAGE_SEO.title ?? ''}
        description={PAGE_SEO.metaDesc ?? ''}
        ogTitle={PAGE_SEO.opengraphTitle}
        ogDescription={PAGE_SEO.opengraphDescription}
        ogImage={PAGE_SEO.opengraphImage?.sourceUrl}
        twitterTitle={PAGE_SEO.twitterTitle}
        twitterDescription={PAGE_SEO.twitterDescription}
        twitterImage={PAGE_SEO.twitterImage?.sourceUrl}
      />
      <Hero
        heading={PAGE?.utvBannerTitle!}
        image={{
          src: PAGE?.utvBannerHeroImg?.localFile?.childImageSharp
            ?.gatsbyImageData!,
          alt: PAGE?.utvBannerHeroImg?.altText!,
        }}
      />
      <Breadcrumbs crumbs={[{ name: data?.wpPage?.title! }]} />
      <Intro
        heading={PAGE?.utvDescriptionTitle!}
        text={PAGE?.utvDescriptionText!}
      />
      {UTV_VEHICLES.length > 0 && <Products products={UTV_VEHICLES} />}
      <Infobox
        heading={PAGE?.utvSeparatorText!}
        image={{
          src: PAGE?.utvSeparatorImg?.localFile?.childImageSharp
            ?.gatsbyImageData!,
          alt: PAGE?.utvSeparatorImg?.altText!,
        }}
      />
      {ABOUT_SECTIONS.length > 0 && (
        <About heading={PAGE?.utvAdvTitle!} sections={ABOUT_SECTIONS} />
      )}
      <Contact
        heading={PAGE?.utvContactTitle!}
        text="Skontaktuj się z nami"
        phone={PAGE?.utvContactPhoneNumber!}
        email={PAGE?.utvContactEmail!}
      />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query UtvPage {
    wpPage(slug: { regex: "/utv/" }) {
      title
      utv {
        utvBannerTitle
        utvDescriptionTitle
        utvDescriptionText
        utvSeparatorText
        utvAdvTitle
        utvAdvSubtitle1
        utvAdvSubtitle2
        utvAdvSubtitle3
        utvAdvDescription1
        utvAdvDescription2
        utvAdvDescription3
        utvContactTitle
        utvContactEmail
        utvContactPhoneNumber
        utvBannerHeroImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1920
                placeholder: DOMINANT_COLOR
                breakpoints: [1280, 1920]
              )
            }
          }
        }
        utvSeparatorImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1920)
            }
          }
        }
        utvAdvImg1 {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1250)
            }
          }
        }
        utvAdvImg2 {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1250)
            }
          }
        }
        utvAdvImg3 {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1250)
            }
          }
        }
        popupImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        popupDelay
        popupLink
        popupVisibleOnMobile
      }
      seo {
        ...WpSEO
      }
    }
    allWpVehiclesArticle(
      filter: { produkt: { productOptionsProductType: { eq: "UTV" } } }
    ) {
      nodes {
        date
        slug
        produkt {
          productOptionsProductType
          productOptionsProductName
          productOptionsPriceFrom
          productOptionsNoPrice
          productOptionsProductImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 700)
              }
            }
          }
        }
      }
    }
  }
`
